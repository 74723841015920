<template>
  <v-row>
    <v-col md="12">
      <v-row class="tree-view__row" @click="handleRowClick(node)">
        <v-col md="1" class="d-flex">
          <span>{{ $t(node.id) }} </span>
        </v-col>
        <v-col md="4">
          <p
            :style="`transform:translateX(-${depth * 40}px)`"
            class="tree-view__leaf-text"
          >
            <v-icon v-if="node.model == 'MATERIAL'">mdi-palette-swatch </v-icon>
            <v-icon v-if="node.model == 'FOLDER'">mdi-menu-left</v-icon>
            <v-icon v-if="node.model == 'FOLDER'">mdi-folder</v-icon>
            <span>{{ $t(node.serial) }}- {{ $t(node.name) }}</span>
          </p>
        </v-col>
        <v-col md="2" class="d-flex justify-end">
          <span>{{ $t(node.income.toLocaleString()) }} </span>
        </v-col>
        <v-col md="1" class="d-flex justify-end">
          <span>{{ $t(node.outcome.toLocaleString()) }} </span>
        </v-col>
        <v-col md="1" class="d-flex justify-end">
          <span>{{ $t(node.balance) }} </span>
        </v-col>
        <v-col md="3" class="d-flex justify-end" v-if="node.model != 'mah'">
          <!-- <v-btn icon><v-icon class="ml-1">mdi-eye</v-icon></v-btn> -->
          <EditMaterial
            @reFetchTree="$emit('reFetchTree')"
            v-if="node.model == 'MATERIAL'"
            :material="node"
          />
          <AddFolder
            @reFetchTree="$emit('reFetchTree')"
            v-if="node.model == 'FOLDER'"
            :folder="node"
            :isNew="false"
          />
          <deleteMaterialFolder @record-deleted="$emit('reFetchTree')" :type="node.model" :id="node.id" />
        </v-col>
      </v-row>
      <v-slide-y-transition mode="out-in" group>
        <template v-if="expandedChildrenStatus">
          <tree
            v-for="child in node.children"
            :key="child.id + child.serial"
            :node="child"
            :expandChildren="child.expandChildren"
            :depth="depth + 1"
            @fetch-my-children="(node) => $emit('fetch-my-children', node)"
            @folderOpened="(node) => $emit('folderOpened', node)"
            @reFetchTree="(node) => $emit('reFetchTree',node)"
          >
          </tree>
        </template>
      </v-slide-y-transition>
    </v-col>
  </v-row>
</template>

<script>
import EditMaterial from "./EditMaterial.vue";
import AddFolder from "./AddFolderModal.vue";
import deleteMaterialFolder from './delete.vue'

export default {
  name: "tree",
  components: { EditMaterial, AddFolder, deleteMaterialFolder },
  data() {
    return {
      expandChild: this.expandChildren,
    };
  },
  methods: {
    handleRowClick(node) {
      if (node.model == "MATERIAL") {
        // if (this.returnAbility("account:statement")) {
        // this.$router.push(`/accountStatement/${node.id}`);
        // }
      } else {
        this.getChildren();
      }
    },
    getChildren() {
      console.log(this.node);
      if (this.node.children.length == 0) {
        this.$emit("fetch-my-children", this.node);
        this.expandChild = !this.expandChild;
      } else {
        this.$emit("folderOpened", this.node);
        this.expandChild = !this.expandChild;
      }
    },
  },

  computed: {
    expandedChildrenStatus() {
      return this.expandChild;
    },
  },

  props: {
    node: Object,
    expandChildren: Boolean,
    depth: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style>
.tree-view__row {
  transition: 0.5s;
  cursor: pointer;
  border-radius: 10px;
}

.tree-view__row:hover {
  background: #c2c2c0;
}

.tree-view__leaf-text {
  font-size: 1.1rem;
  margin: 0 !important;
}
</style>
