<template>
  <div>
    <v-dialog v-model="dialog" width="600" @input="openHandler">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="isNew"
          v-bind="attrs"
          v-on="on"
          depressed
          rounded
          class="add-modal__btn"
        >
          <v-icon color="#757575">mdi-plus</v-icon>
          {{ $t("add folder") }}
        </v-btn>
        <v-btn v-else v-bind="attrs" v-on="on" icon
          ><v-icon class="ml-1" color="blue">mdi-pen</v-icon></v-btn
        >
      </template>
      <v-card>
        <v-card-title class="d-flex justify-center">
          <h2>{{ isNew ? $t('Create folder') : $t('Edit Folder') }}</h2>
        </v-card-title>
        <v-card-text class="pt-5">
          <v-text-field
            v-model="folderData.name"
            :label="$t('folder name')"
            outlined
            dense
            color="#757575"
          ></v-text-field>
          <!-- :disabled="!isNew && !folder.parent_id" -->
          <v-autocomplete
            :label="$t('Parent Folder')"
            outlined
            dense
            v-model="folderData.parent"
            color="#757575"
            item-text="name"
            item-value="id"
            :loading="itemsLoading"
            :items="folderItems"
            return-object
            no-filter
            v-debounce="fetchAutoComplete"
            @input="parentFolderInputHandler"
          ></v-autocomplete>
          <!-- :disabled="!isNew && !folder.parent_id" -->
          <div class="d-flex justify-center align-center">
            <v-text-field
              v-model="folderData.serial"
              :label="$t('folder serial')"
              outlined
              dense
              color="#757575"
            ></v-text-field>

            <!-- :disabled="!isNew && !folder.parent_id" -->
            <p class="pb-2">-{{ getParentSerial }}</p>
          </div>
          <v-autocomplete
            :label="$t('Material Type')"
            outlined
            dense
            v-model="folderData.material_type_id"
            color="#757575"
            item-text="name"
            item-value="id"
            :loading="material_typeLoading"
            :items="material_typeItems"
            return-object
            no-filter
            v-debounce="material_typeAutoComplete"
            
          ></v-autocomplete>
          <v-row>
            <v-col md="12">
              <h4 style="text-align: center">{{ $t('Corresponding Account') }}</h4>
            </v-col>
            <v-col md="6">
              <v-autocomplete
                :label="$t('sales account')"
                outlined
                dense
                v-model="folderData.sales_account_id"
                color="#757575"
                item-text="name"
                item-value="id"
                :loading="salesLoading"
                :items="salesItems"
                return-object
                no-filter
                v-debounce="salesFetchAutoComplete"
              ></v-autocomplete>
            </v-col>
            <v-col md="6">
              <v-autocomplete
                :label="$t('sales refund account')"
                outlined
                dense
                v-model="folderData.sales_refund_account_id"
                color="#757575"
                item-text="name"
                item-value="id"
                :loading="salesRefundLoading"
                :items="salesRefundItems"
                return-object
                no-filter
                v-debounce="salesRefundFetchAutoComplete"
              ></v-autocomplete>
            </v-col>
            <v-col md="6">
              <v-autocomplete
                :label="$t('purchases account')"
                outlined
                dense
                v-model="folderData.purchases_account_id"
                color="#757575"
                item-text="name"
                item-value="id"
                :loading="purchaseLoading"
                :items="purchasesItems"
                return-object
                no-filter
                v-debounce="purchaseFetchAutoComplete"
              ></v-autocomplete>
            </v-col>
            <v-col md="6">
              <v-autocomplete
                :label="$t('purchases refund account')"
                outlined
                dense
                v-model="folderData.purchases_refund_account_id"
                color="#757575"
                item-text="name"
                item-value="id"
                :loading="purchaseRefundLoading"
                :items="purchasesRefundItems"
                return-object
                no-filter
                v-debounce="purchaseRefundFetchAutoComplete"
              ></v-autocomplete>
              
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <!-- :disabled="!isNew && !folder.parent_id" -->
          <v-btn
            depressed
            color="blue-grey"
            class="white--text"
            @click="addFolder"
            :loading="submitFolderBtnLoading"
            >{{ $t("storage") }}</v-btn
          >
          <v-btn depressed color="primary" @click="dialog = false">{{
            $t("cancel")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import Notifications from "../../../../helpers/toast-notifications";
export default {
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    folder: {
      type: Object,
    },
  },
  data() {
    return {
      folderData: {
        id: null,
        name: null,
        parent: {
          id: null,
        },
        serial: null,
        type: "",
        sales_account_id: null,
        purchases_account_id: null,
        sales_refund_account_id: null,
        purchases_refund_account_id: null,
        material_type_id: null,
        // cash: 0,
      },
     
      material_typeLoading: false,
      material_typeItems: [],
      salesLoading: false,
      salesItems: [],
      purchaseLoading: false,
      purchasesItems: [],
      salesRefundLoading: false,
      salesRefundItems: [],
      purchaseRefundLoading: false,
      purchasesRefundItems: [],
      types: ["trading", "profits_and_losses", "balance"],
      dialog: false,
      itemsLoading: false,
      folderItems: [],
      parentSerial: null,
      submitFolderBtnLoading: false,
    };
  },
  computed: {
    getParentSerial() {
      return (this.parentSerial = this.folderData.parent?.serial);
    },
  },
  methods: {
    async parentFolderInputHandler() {
      const result = await axios.get(`/inventories/folders/${this.folderData.parent.id}`)
      const tempAccounts = result.data.data
      if (tempAccounts.sales_account) {
          this.folderData.sales_account_id = tempAccounts.sales_account;
          this.salesItems.push(tempAccounts.sales_account);
        }
        if (tempAccounts.material_type) {
          this.folderData.material_type_id = tempAccounts.material_type;
          this.material_typeItems.push(tempAccounts.material_type);
        }
        if (tempAccounts.sales_refund_account) {
          this.folderData.sales_refund_account_id =
          tempAccounts.sales_refund_account;
          this.salesRefundItems.push(tempAccounts.sales_refund_account);
        }
        if (tempAccounts.purchases_account) {
          this.folderData.purchases_account_id = tempAccounts.purchases_account;
          this.purchasesItems.push(tempAccounts.purchases_account);
        }
        if (tempAccounts.purchases_refund_account) {
          this.folderData.purchases_refund_account_id =
          tempAccounts.purchases_refund_account;
          this.purchasesRefundItems.push(tempAccounts.purchases_refund_account);
        }
    },
    async openHandler() {
      if (this.folder && !this.isNew) {
        console.log(this.folder);
        this.folderData.serial = this.folder.serial;
        if (this.folder.parent_id) {
          const result = await axios.get(
            `/inventories/folders/${this.folder.parent_id}`
          );
          const parent = result.data.data;
          this.folderItems.push(parent);
          this.folderData.parent = parent;
          this.folderData.parent.serial = parent.serial;
          const parentSerialLength = parent.serial.length;
          this.folderData.serial = this.folderData.serial.slice(
            parentSerialLength,
            this.folderData.serial.length
          );
        }
        this.folderData.id = this.folder.id;
        this.folderData.name = this.folder.name;
        if (this.folder.sales_account) {
          this.folderData.sales_account_id = this.folder.sales_account;
          this.salesItems.push(this.folder.sales_account);
        }
        if (this.folder.material_type) {
          this.folderData.material_type_id = this.folder.material_type;
          this.material_typeItems.push(this.folder.material_type);
        }
        if (this.folder.sales_refund_account) {
          this.folderData.sales_refund_account_id =
            this.folder.sales_refund_account;
          this.salesRefundItems.push(this.folder.sales_refund_account);
        }
        if (this.folder.purchases_account) {
          this.folderData.purchases_account_id = this.folder.purchases_account;
          this.purchasesItems.push(this.folder.purchases_account);
        }
        if (this.folder.purchases_refund_account) {
          this.folderData.purchases_refund_account_id =
            this.folder.purchases_refund_account;
          this.purchasesRefundItems.push(this.folder.purchases_refund_account);
        }
      }
    },
    async salesFetchAutoComplete(value) {
      try {
        if (!value) return;
        this.salesLoading = true;
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.salesItems = searchResult.data.data;
      } catch (err) {
      } finally {
        this.salesLoading = false;
      }
    },
    async material_typeAutoComplete(value) {
      try {
        if (!value) return;
        this.material_typeLoading = true
        const searchResult = await axios.get(
          "inventories/material-types/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.material_typeItems = searchResult.data.data;
      } catch (err) {}
      finally {
        this.material_typeLoading = false
      }
    },
    async salesRefundFetchAutoComplete(value) {
      try {
        if (!value) return;
        this.salesRefundLoading = true;
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.salesRefundItems = searchResult.data.data;
      } catch (err) {
      } finally {
        this.salesRefundLoading = false;
      }
    },
    async purchaseFetchAutoComplete(value) {
      try {
        if (!value) return;
        this.purchaseLoading = true;
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.purchasesItems = searchResult.data.data;
      } catch (err) {
      } finally {
        this.purchaseLoading = false;
      }
    },
    async purchaseRefundFetchAutoComplete(value) {
      try {
        if (!value) return;
        this.purchaseRefundLoading = true;
        const searchResult = await axios.get(
          "accounting/account/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.purchasesRefundItems = searchResult.data.data;
      } catch (err) {
      } finally {
        this.purchaseRefundLoading = false;
      }
    },
    async addFolder() {
      try {
        this.submitFolderBtnLoading = true;
        let serial = this.folderData.serial;
        if (this.folderData.parent.serial) {
          serial = this.folderData.parent.serial + this.folderData.serial;
        }
        if (this.isNew) {
          await axios.post("/inventories/folders", {
            name: this.folderData.name,
            parent_id: this.folderData.parent.id,
            serial,
            sales_account_id: this.folderData.sales_account_id?.id,
            material_type_id: this.folderData.material_type_id?.id,
            purchases_account_id: this.folderData.purchases_account_id?.id,
            sales_refund_account_id:
              this.folderData.sales_refund_account_id?.id,
            purchases_refund_account_id:
              this.folderData.purchases_refund_account_id?.id,
          });
        } else {
          console.log("123");
          await axios.put(`/inventories/folders/${this.folderData.id}`, {
            name: this.folderData.name,
            parent_id: this.folderData.parent.id,
            serial,
            sales_account_id: this.folderData.sales_account_id?.id,
            material_type_id: this.folderData.material_type_id?.id,
            purchases_account_id: this.folderData.purchases_account_id?.id,
            sales_refund_account_id:
              this.folderData.sales_refund_account_id?.id,
            purchases_refund_account_id:
              this.folderData.purchases_refund_account_id?.id,
          });
        }
        this.dialog = false;
        this.$emit("reFetchTree");
        Notifications(
          this.$t('add success'),
          { timeout: 2000, rtl: true },
          "success"
        );
      } catch (err) {
        console.log(err);
      } finally {
        this.submitFolderBtnLoading = false;
      }
    },
    async fetchAutoComplete(value) {
      try {
        if (!value) return;
        this.itemsLoading = true;
        const searchResult = await axios.get(
          "inventories/folders/auto-complete",
          {
            params: {
              query: value,
            },
          }
        );
        this.folderItems = searchResult.data.data;
      } catch (err) {
      } finally {
        this.itemsLoading = false;
      }
    },
  },
};
</script>

<style>
.account-specifications__add-icon {
  color: #757575;
  border: 1px solid #757575;
  border-radius: 50%;
  padding: 3px;
}

.account-specifications__add-icon:hover {
  transform: scale(1.1);
}
</style>
